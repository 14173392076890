const form = {
  name: "Test 4",
  description: "This the fourth of the test forms",
  inputs: [
    { label: "Name", variable: "name", description: "Your legal first and last name.", type: "STRING" },
    {
      label: "Favorite Color",
      variable: "favoriteColor",
      description: "Has to be your very favorite color.. ever!",
      type: "STRING",
    },
  ],
};

export default form;
