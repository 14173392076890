// External Imports
import { useState } from "react";

const Select = ({ value, onChange, options = [], style = {} }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          ...style,
          textAlign: "left",
        }}
        onClick={() => setOpen(!open)}
      >
        {value || "Select one"}
      </div>
      {open && (
        <div
          style={{
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "0px 0px 10px 10px",
            position: "absolute",
            background: "white",
            overflow: "auto",
            maxHeight: 250,
            fontSize: 18,
            padding: 10,
            zIndex: 10,
            left: 0,
            top: 48,
          }}
        >
          {Array.isArray(options) && options.length > 0
            ? options.map((option) => (
                <div key={option}>
                  <div
                    style={{ textAlign: "left", padding: "0px 10px" }}
                    onClick={() => {
                      onChange(option);
                      setOpen(false);
                    }}
                  >
                    {option}
                  </div>
                  <hr style={{ borderTop: "1px solid #D3D3D3" }} />
                </div>
              ))
            : "No options"}
        </div>
      )}
    </div>
  );
};

const Input = ({ type, value, onChange }: any) => {
  switch (type) {
    case "STRING":
      // @ts-expect-error TS(2322): Type '{ border: string; boxSizing: string; width: ... Remove this comment to see the full error message
      return <input style={inputStyle} value={value} onChange={(e) => onChange(e.target.value)} />;

    case "NUMBER":
      return (
        // @ts-expect-error TS(2322): Type '{ border: string; boxSizing: string; width: ... Remove this comment to see the full error message
        <input type="number" style={inputStyle} value={value} onChange={(e) => onChange(e.target.value)} />
      );

    case "DATE":
      return (
        // @ts-expect-error TS(2322): Type '{ border: string; boxSizing: string; width: ... Remove this comment to see the full error message
        <input type="date" style={inputStyle} value={value} onChange={(e) => onChange(e.target.value)} />
      );

    case "STATE":
      return (
        <Select options={US_STATES} style={inputStyle} value={value} onChange={(v: any) => onChange(v)} />
      );

    case "LOSS":
      return (
        <Select options={LOSS_TYPES} style={inputStyle} value={value} onChange={(v: any) => onChange(v)} />
      );

    default:
      // @ts-expect-error TS(2322): Type '{ border: string; boxSizing: string; width: ... Remove this comment to see the full error message
      return <input style={inputStyle} value={value} onChange={(e) => onChange(e.target.value)} />;
  }
};

const inputStyle = {
  border: "2px solid gray",
  boxSizing: "border-box",
  width: "100%",
  borderRadius: 10,
  marginBottom: 50,
  fontSize: 18,
  padding: 10,
};

const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const LOSS_TYPES = [
  "Wind",
  "Structural",
  "Debris Impact",
  "Water",
  "Fire",
  "Smoke",
  "Other Impact",
  "Lightning",
  "Hail",
  "Freezing",
  "Snow/Ice",
  "Theft",
  "Vandalism",
];

export default Input;
