// External Imports
import { useNavigate } from "react-router-dom";

// Internal Imports
import Header from "components/Header";
import { slugify } from "utils";
import { forms } from "forms";

const FormOption = ({ option }: any) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
        display: "inline-grid",
        borderRadius: 15,
        padding: 20,
      }}
    >
      <div style={{ fontSize: 24, fontWeight: 600, marginBottom: 10 }}>{option.name}</div>
      <div style={{ fontSize: 18, marginBottom: 30 }}>{option.description}</div>
      <div
        style={{
          background: "#46C695",
          textAlign: "center",
          color: "white",
          marginBottom: 10,
          borderRadius: 10,
          fontWeight: 600,
          fontSize: 18,
          padding: 10,
        }}
        onClick={() => navigate(`/form-wizard/${slugify(option.name)}`)}
      >
        Get Started
      </div>
    </div>
  );
};

const FormOptions = () => {
  return (
    <>
      <Header />

      <div
        style={{
          color: "#9da3ae", //"#46C695",
          fontWeight: 650,
          fontSize: 36,
          margin: 100,
          marginBottom: 50,
        }}
      >
        Brelly Form Wizard
      </div>
      <div
        style={{
          gridTemplateColumns: "repeat(3, 1fr)",
          display: "grid",
          width: "100wv",
          gridColumnGap: 50,
          gridRowGap: 50,
          padding: "0px 100px",
        }}
      >
        {forms.map((option) => (
          <FormOption key={option.name} option={option} />
        ))}
      </div>
    </>
  );
};

export default FormOptions;
