export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
