// External Imports
import React from "react";
import { useSlate } from "slate-react";

// Internal Imports
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from "./utils";
import { TEXT_ALIGN_TYPES } from "./constants";

// @ts-expect-error TS(2339): Property 'active' does not exist on type '{}'.
const Button = React.forwardRef(({ active, reversed, ...props }, ref) => (
  <span
    {...props}
    // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    ref={ref}
    style={{
      fontSize: 24,
      cursor: "pointer",
      color: reversed ? (active ? "white" : "#aaa") : active ? "black" : "#ccc",
    }}
  />
));

export const BlockButton = ({
  format,
  children
}: any) => {
  const editor = useSlate();

  return (
    // @ts-expect-error TS(2322): Type '{ children: any; active: boolean; onMouseDow... Remove this comment to see the full error message
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {children}
    </Button>
  );
};

export const MarkButton = ({
  format,
  children
}: any) => {
  const editor = useSlate();

  return (
    // @ts-expect-error TS(2322): Type '{ children: any; active: boolean; onMouseDow... Remove this comment to see the full error message
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {children}
    </Button>
  );
};
