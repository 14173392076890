const form = {
  name: "Proof of Loss",
  slug: "proof-of-loss",
  description: "Official letter to insurance verifying loss.",
  inputs: [
    {
      label: "Policyholder Name",
      variable: "policyholderName",
      description: "Who is the primary holder of the insuance policy? (first and last)",
      type: "STRING",
    },
    {
      label: "Insurance Carrier",
      variable: "carrier",
      description: "Who is your insurance provider?",
      type: "STRING",
    },
    {
      label: "Insurance Agency",
      variable: "agency",
      description: "What agency did you purchase you insurance from?",
      type: "STRING",
    },
    {
      label: "Insurance Agent",
      variable: "agent",
      description: "What agent did you purchase you insurance from?",
      type: "STRING",
    },
    {
      label: "Policy Number",
      variable: "policyNumber",
      description: "What is the number assigned to the policy you filed this claim under?",
      type: "STRING",
    },
    {
      label: "Total Policy Amount",
      variable: "policyAmount",
      description: "What is the total value of your policy at the time of loss?",
      type: "NUMBER",
    },
    {
      label: "Policy Issued Date",
      variable: "policyIssueDate",
      description: "When was your policy issued?",
      type: "DATE",
    },
    {
      label: "Policy Expiry Date",
      variable: "policyExpireDate",
      description: "When does your policy expire?",
      type: "DATE",
    },
    {
      label: "Claim Number",
      variable: "claimNumber",
      description: "What is the number assigned to the claim you filed with your insurance provider?",
      type: "STRING",
    },
    {
      label: "State of Loss",
      variable: "stateOfLoss",
      description: "What state did this loss occur in?",
      type: "STATE",
    },
    {
      label: "Type of Loss",
      variable: "lossType",
      description: "What type of loss is filed in this claim?",
      type: "LOSS",
    },
    {
      label: "Date of Loss",
      variable: "dateOfLoss",
      description: "When did the loss event occur?",
      type: "DATE",
    },
    {
      label: "Cause of Loss",
      variable: "causeOfLoss",
      description: "What was the cause of this loss event?",
      type: "STRING",
    },
    {
      label: "Amount of Loss",
      variable: "lossAmount",
      description: "What was the total value of the loss?",
      type: "NUMBER",
    },
    {
      label: "Amount of Claim",
      variable: "claimAmount",
      description: "What was the total amount claimed?",
      type: "NUMBER",
    },
    {
      label: "Property Occupancy",
      variable: "occupancy",
      description: "Who occupied the home at the time of loss?",
      type: "STRING",
    },
    {
      label: "Insured Interest in Property",
      variable: "insuredInterest",
      description: "What is your interest in this property? (i.e. owner)",
      type: "STRING",
    },
    {
      label: "External Interest in Property",
      variable: "externalInterests",
      description: "Does anyone else have an interest in this property? (i.e. mortgage company)",
      type: "STRING",
    },
    {
      label: "Property Changes",
      variable: "propertyChanges",
      description: "Have there been any changes to the property since the policy was made?",
      type: "STRING",
    },
    {
      label: "Property Value",
      variable: "propertyValue",
      description: "What was the actual cash value of the property at the time of loss?",
      type: "NUMBER",
    },
  ],
};

export default form;
