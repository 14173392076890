const baseContainerStyle = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
};

const baseDotStyle = {
  borderRadius: "50%",
  marginRight: 11,
  marginLeft: 11,
  height: 11,
  width: 11,
};

const activeBaseDotStyle = {
  ...baseDotStyle,
  background: "#46C695",
  height: 15,
  width: 15,
};

const inactiveBaseDotStyle = {
  ...baseDotStyle,
  background: "gray",
};

const ProgressDots = ({
  activeIndex,
  numDots,
  style = {},
  activeDotStyle = {},
  inactiveDotStyle = {}
}: any) => {
  return (
    <div style={{ ...baseContainerStyle, ...style }}>
      {[...Array(numDots).keys()].map((idx) => {
        const dotStyle =
          idx === activeIndex
            ? { ...activeBaseDotStyle, ...activeDotStyle }
            : { ...inactiveBaseDotStyle, ...inactiveDotStyle };
        return <div key={idx} style={dotStyle} />;
      })}
    </div>
  );
};

export default ProgressDots;
