// External Imports
import { useSlate } from "slate-react";
import { BsCodeSlash, BsQuote, BsJustify } from "react-icons/bs";
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
  AiOutlineOrderedList,
  AiOutlineUnorderedList,
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
  AiOutlineSave,
} from "react-icons/ai";

// Internal Imports
import { BlockButton, MarkButton } from "./components";
import { toHtml } from "./utils";

const Toolbar = () => {
  const editor = useSlate();

  const serialize = () => {
    const htmlStr = toHtml(editor);
    console.log("htmlStr", htmlStr);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
      <MarkButton format="bold" icon="format_bold">
        <AiOutlineBold />
      </MarkButton>
      <MarkButton format="italic" icon="format_italic">
        <AiOutlineItalic />
      </MarkButton>
      <MarkButton format="underline" icon="format_underlined">
        <AiOutlineUnderline />
      </MarkButton>
      <MarkButton format="code" icon="code">
        <BsCodeSlash />
      </MarkButton>
      <BlockButton format="heading-one" icon="looks_one">
        <span style={{ verticalAlign: "center" }}>H1</span>
      </BlockButton>
      <BlockButton format="heading-two" icon="looks_two">
        <span style={{ verticalAlign: "center" }}>H2</span>
      </BlockButton>
      <BlockButton format="block-quote" icon="format_quote">
        <BsQuote />
      </BlockButton>
      <BlockButton format="numbered-list" icon="format_list_numbered">
        <AiOutlineOrderedList />
      </BlockButton>
      <BlockButton format="bulleted-list" icon="format_list_bulleted">
        <AiOutlineUnorderedList />
      </BlockButton>
      <BlockButton format="left" icon="format_align_left">
        <AiOutlineAlignLeft />
      </BlockButton>
      <BlockButton format="center" icon="format_align_center">
        <AiOutlineAlignCenter />
      </BlockButton>
      <BlockButton format="right" icon="format_align_right">
        <AiOutlineAlignRight />
      </BlockButton>
      <BlockButton format="justify" icon="format_align_justify">
        <BsJustify />
      </BlockButton>
      <span style={{ fontSize: 24, cursor: "pointer" }}>
        <AiOutlineSave onClick={serialize} />
      </span>
    </div>
  );
};

export default Toolbar;
