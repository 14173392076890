// External Imports
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// Internal Imports
import { slugify, downloadBlob } from "utils";
import ProgressDots from "components/ProgressDots";
import Header from "components/Header";
import { forms } from "forms";
import Input from "./Input";

const baseBtnStyle = {
  textAlign: "center",
  color: "gray",
  borderRadius: 10,
  fontWeight: 600,
  fontSize: 18,
  padding: 10,
  width: 150,
};

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8080/form";
console.log("apiUrl:", apiUrl);

const FormWizard = () => {
  document.body.style.overflow = "hidden";
  const [inputIndex, setInputIndex] = useState(0);
  const [values, setValues] = useState({});
  const [form, setForm] = useState(null);
  const { formSlug } = useParams();

  // @ts-expect-error TS(2339): Property 'inputs' does not exist on type 'never'.
  const currentInput = Array.isArray(form?.inputs) ? form.inputs[inputIndex] : null;
  // @ts-expect-error TS(2339): Property 'inputs' does not exist on type 'never'.
  const numInputs = Array.isArray(form?.inputs) ? form.inputs.length : 0;

  useEffect(() => {
    const selectedForm = forms.find((f) => formSlug === slugify(f.name));
    // @ts-expect-error TS(2345): Argument of type '{ name: string; description: str... Remove this comment to see the full error message
    setForm(selectedForm);
  }, [formSlug]);

  const generateDoc = () =>
    axios
      .post(`${apiUrl}/v0/forms/${formSlug}`, values, {
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        downloadBlob(blob);
      })
      .catch((err) => console.error("Failed to download form", err));

  if (form) {
    return (
      <>
        <Header />

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "80vh",
          }}
        >
          <div
            style={{
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
              padding: "40px 70px",
              background: "white",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#9da3ae",
                width: "100%",
                marginBottom: 10,
                fontWeight: 650,
                fontSize: 36,
              }}
            >
              {/* @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'. */}
              {form.name}
            </div>

            {currentInput && (
              <div style={{ minWidth: 500, textAlign: "center" }}>
                <div style={{ marginBottom: 50, fontSize: 18 }}>{currentInput.description}</div>
                <Input
                  type={currentInput.type || ""}
                  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  value={values[currentInput.variable] || ""}
                  onChange={(v: any) => setValues({ ...values, [currentInput.variable]: v || "" })}
                />

                {/* <input
                type="number"
                // type="date"
                style={{
                  border: "2px solid gray",
                  boxSizing: "border-box",
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: 50,
                  fontSize: 18,
                  padding: 10,
                }}
                value={values[currentInput.variable] || ""}
                onChange={(e) => setValues({ ...values, [currentInput.variable]: e.target.value || "" })}
              /> */}

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
                  <div
                    // @ts-expect-error TS(2322): Type '{ border: string; color: "gray" | "#46C695";... Remove this comment to see the full error message
                    style={{
                      ...baseBtnStyle,
                      border: `2px solid ${inputIndex > 0 ? "#46C695" : "gray"}`,
                      color: inputIndex > 0 ? "#46C695" : "gray",
                    }}
                    onClick={() => inputIndex > 0 && setInputIndex(inputIndex - 1)}
                  >
                    Back
                  </div>

                  <div
                    // @ts-expect-error TS(2322): Type '{ background: any; border: string; color: "g... Remove this comment to see the full error message
                    style={{
                      ...baseBtnStyle,
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      background: values[currentInput.variable] && "#46C695",
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      border: `2px solid ${values[currentInput.variable] ? "#46C695" : "gray"}`,
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      color: values[currentInput.variable] ? "white" : "gray",
                    }}
                    onClick={() =>
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      values[currentInput.variable] &&
                      (inputIndex < numInputs - 1 ? setInputIndex(inputIndex + 1) : generateDoc())
                    }
                  >
                    {inputIndex < numInputs - 1 ? "Next" : "Finish"}
                  </div>
                </div>

                <ProgressDots numDots={numInputs} activeIndex={inputIndex} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default FormWizard;
