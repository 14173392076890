export const slugify = (name: any) => typeof name === "string" ? name.toLowerCase().replaceAll(" ", "-") : "";

export const fillTemplateVariables = (htmlStr: any, variables: any) => {
  if (!htmlStr) {
    console.error("Missing or invalid htmlStr");
    return "";
  }

  if (typeof htmlStr !== "string") {
    console.error("htmlStr must be of type string, recieved", typeof htmlStr);
    return "";
  }

  if (!variables) {
    console.error("Missing or invalid variables");
    return htmlStr;
  }

  if (typeof variables !== "object") {
    console.error("variables must be of type object, recieved", typeof variables);
    return htmlStr;
  }

  var filledHtmlStr = htmlStr;
  Object.entries(variables).forEach(([name, value]) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    filledHtmlStr = filledHtmlStr.replaceAll(`{{${name}}}`, value);
  });

  if (filledHtmlStr.includes("{{")) {
    console.error("Missing variable. Not all variables have been replaced!");
  }

  return filledHtmlStr;
};

export const downloadBlob = (blob: any, filename = "Document.pdf") => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.style.visibility = "hidden";

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = filename;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};
