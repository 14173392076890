const form = {
  name: "Payment Demand",
  slug: "payment-demand",
  description: "This the first of the test forms",
  inputs: [
    {
      label: "Insurance Company Name",
      variable: "insuranceCompany",
      description: "What insurance company are you dealing with?",
      type: "STRING",
    },
    {
      label: "Demand Amount",
      variable: "amount",
      description: "How much are you demanding?",
      type: "NUMBER",
    },
    {
      label: "First Request Date",
      variable: "requestDate",
      description: "When did you first request this money?",
      type: "STRING",
    },
    {
      label: "Client Name",
      variable: "clientName",
      description: "What is the full name of your client?",
      type: "STRING",
    },
    { label: "State", variable: "state", description: "In what state did this claim occur?", type: "STATE" },
    {
      label: "Adjuster Name",
      variable: "adjusterName",
      description: "What is your full name?",
      type: "STRING",
    },
  ],
};

export default form;
