// External Imports
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

// Internal Imports
import FormOptions from "pages/FormOptions";
import FormBuilder from "pages/FormBuilder";
import FormWizard from "pages/FormWizard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/form-wizard" replace={true} />,
  },
  {
    path: "/form-wizard",
    element: <FormOptions />,
  },
  {
    path: "/form-wizard/:formSlug",
    element: <FormWizard />,
  },
  {
    path: "/form-wizard/builder",
    element: <FormBuilder />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
