const aStyle = {
  padding: "10px 5px",
  fontWeight: 500,
  color: "black",
  textDecoration: "none",
};

const Header = () => {
  return (
    <div
      style={{
        borderBottom: "1px solid rgba(0,0,0,.1)",
        background: "hsla(0,0%,100%,.95)",
        justifyContent: "center",
        alignItems: "center",
        position: "sticky",
        display: "flex",
        width: "100vw",
        fontSize: 16,
        zIndex: 999,
        padding: 0,
        top: 0,
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          padding: "0px 15px",
          display: "flex",
          width: "100%",
          maxWidth: 1360,
        }}
      >
        <a href="https://brelly.com/">
          <img
            alt="brelly-logo"
            src="/assets/imgs/textLogo.png"
            style={{ height: 29, margin: "17px 15px 13px" }}
          />
        </a>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ padding: "10px 18px" }}>
            <a href="https://brelly.com/claim-manager/" style={aStyle}>
              Claim Manager
            </a>
          </div>

          <div style={{ padding: "10px 18px" }}>
            <a href="https://brelly.com/claim-resources/" style={aStyle}>
              Resources
            </a>
          </div>

          <div style={{ padding: "10px 18px" }}>
            <a href="https://brelly.com/contact/" style={aStyle}>
              Contact
            </a>
          </div>

          <div style={{ padding: "10px 18px" }}>
            <a href="https://brelly.com/about/" style={aStyle}>
              About
            </a>
          </div>

          <div style={{ padding: "10px 18px" }}>
            <a
              href="https://brelly.com/app-form/"
              style={{
                ...aStyle,
                padding: "12px 15px 14px",
                background: "#46c695",
                color: "white",
                borderRadius: 6,
              }}
            >
              Get Started for Free
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
