// External Imports
import { Editor, Transforms, Element, Text } from "slate";
import { jsx } from "slate-hyperscript";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'esca... Remove this comment to see the full error message
import escapeHtml from "escape-html";

// Internal Imports
import { LIST_TYPES, TEXT_ALIGN_TYPES } from "./constants";

export const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'BaseElemen... Remove this comment to see the full error message
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      // @ts-expect-error TS(2345): Argument of type '{ align: any; } | { type: any; }... Remove this comment to see the full error message
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (editor: any, format: any, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor);
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return marks ? marks[format] === true : false;
};

export const toHtml = (node: any) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    // @ts-expect-error TS(2339): Property 'bold' does not exist on type 'BaseText'.
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }

  const children = node.children.map((n: any) => toHtml(n)).join("");

  switch (node.type) {
    case "block-quote":
      return `<blockquote><p>${children}</p></blockquote>`;

    case "bulleted-list":
      return `<ul>${children}</ul>`;

    case "heading-one":
      return `<h1>${children}</h1>`;

    case "heading-two":
      return `<h2>${children}</h2>`;

    case "list-item":
      return `<li>${children}</li>`;

    case "numbered-list":
      return `<ol>${children}</ol>`;

    case "paragraph":
      return `<p>${children}</p>`;

    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;

    default:
      return children;
  }
};

// @ts-expect-error TS(7023): 'toSlate' implicitly has return type 'any' because... Remove this comment to see the full error message
export const toSlate = (el: any, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case "STRONG":
      // @ts-expect-error TS(2339): Property 'bold' does not exist on type '{}'.
      nodeAttributes.bold = true;
  }

  // @ts-expect-error TS(7022): 'children' implicitly has type 'any' because it do... Remove this comment to see the full error message
  const children = Array.from(el.childNodes)
    .map((node) => toSlate(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);

    case "BR":
      return "\n";

    case "BLOCKQUOTE":
      return jsx("element", { type: "quote" }, children);

    case "P":
      return jsx("element", { type: "paragraph" }, children);

    case "A":
      return jsx("element", { type: "link", url: el.getAttribute("href") }, children);

    default:
      return children;
  }
};

export const fromHtml = (htmlStr: any) => {
  const document = new DOMParser().parseFromString(htmlStr, "text/html");
  return toSlate(document.body);
};
