// External Imports
import { useMemo, useCallback } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'slat... Remove this comment to see the full error message
import SoftBreak from "slate-soft-break";
import { createEditor } from "slate";
import isHotkey from "is-hotkey";

// Internal Imports
import Header from "components/Header";
import Toolbar from "./Toolbar";
import Element from "./Element";
import Leaf from "./Leaf";
import { HOTKEYS } from "./constants";
import { toggleMark } from "./utils";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "Start your form template here." }],
  },
];

const FormBuilder = () => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <>
      <Header />
      <div
        style={{
          background: "#fafafa",
          textAlign: "center",
          color: "#46C695",
          width: "100%",
          fontWeight: 650,
          marginBottom: 50,
          marginTop: 50,
          fontSize: 40,
        }}
      >
        Form Builder
      </div>

      <Slate editor={editor} value={initialValue}>
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ width: "70%", marginBottom: 10, display: "flex" }}>
            <Toolbar />
          </div>

          <div style={{ width: "70%", border: "1px solid grey", padding: 10 }}>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              autoFocus
              // @ts-expect-error TS(2322): Type '{ renderElement: (props: any) => Element; re... Remove this comment to see the full error message
              plugins={[SoftBreak()]}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
          </div>
        </div>
      </Slate>
    </>
  );
};

export default FormBuilder;
